/* Header Styles */
.logo-header {
    max-height: 60px;
}

.header-nav {
    padding: 28px 10px;
    border-bottom: 2px solid transparent;
    min-width: 100px;
    cursor: pointer;
}

.header-nav:hover {
    background-color: white;
    border-bottom: 2px solid var(--color-dark-orange);
}

@media (max-width: 768px) {
    .header-nav {
        padding: 7px 10px;
    }
}

/* Dropdown Menus */
.dropdown-item {
    font-size: 0.8em;
}

#servDropdown {
    top: 79px;
    right: 0;
    z-index: 5;
    display: none;
    text-align: start;
}

#servDropdown li>a {
    border-radius: 4px;
    width: 95%;
}

#servDropdown li>a:hover {
    background-color: white;
}

@media (min-width:576px) {
    .servHBtn:hover #servDropdown {
        display: flex;
    }
}