/* Footer Styles */
.logo-footer {
    width: 120px;
}

.social-icons svg {
    background-color: var(--color-light-blue);
    border-radius: 50%;
    padding: 5px;
    margin-left: 5px;
    width: 18px;
    height: 18px;
}

.social-icons a:hover svg {
    background-color: var(--color-light-gray);
    color: var(--color-dark-orange);
}

footer a {
    color: white;
    text-decoration: none;
}

footer ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.footer-links:not(:last-child) {
    border-bottom: 1px solid var(--color-light-gray);
}

.footer-links:hover a {
    color: var(--color-dark-blue);
    background-color: var(--color-light-gray);
}

/* Typography */

.copy-rights,
address {
    font-family: "Loew Next Arabic", sans-serif;
    font-size: 0.8em;
    border-top: 0.5px solid var(--color-light-blue);
}