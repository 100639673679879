@media (max-width: 768px) {

    /* لإخفاء الـ Navbar عند عدم استخدامه */
    .navbar-collapse {
        position: fixed;
        top: 0;
        left: -250px;
        width: 250px;
        height: 100%;
        background-color: white;
        transition: left 0.3s ease-in-out;
        padding-top: 20px;
        z-index: 1000;
    }

    /* لإظهار الـ Navbar عند النقر على زر القائمة */
    .navbar-collapse.show {
        left: 0;
    }

    .navbar-toggler {
        z-index: 1100;
        /* للتأكد من ظهور الزر فوق النافذة الجانبية */
    }

    .navbar-backdrop {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        z-index: 900;
        display: none;
    }

    .navbar-backdrop.show {
        display: block;
    }

}